// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-caca-pix-primary: mat.define-palette(mat.$indigo-palette);
$app-caca-pix-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-caca-pix-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-caca-pix-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-caca-pix-primary,
      accent: $app-caca-pix-accent,
      warn: $app-caca-pix-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-caca-pix-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  background-color: #200003;
}

@media screen and (min-width: 768px) {
  html,
  body {
    background-color: #200003;
    background-image: url('/assets/bgs/bg-desktop.png');
    background-size: contain;
    background-repeat: repeat;
  }
}

body {
  margin: 0;
  font-family: 'Questrial', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.questrial-regular {
  font-family: 'Questrial', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.title-section {
  color: #ebece5;
}

.title-yellow {
  color: #f4ce26;
}

.bg-aplication {
  background-color: #2c0604;
}

//BUTONS

.mat-mdc-outlined-button,
.mat-mdc-outlined-button:not(:disabled) {
  border: 2px solid !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
}

.mat-mdc-unelevated-button.sec-button:not(:disabled) {
  border: 2px solid #2c0604 !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  color: #2c0604 !important;
  background-color: #ebece5 !important;
}

.btn-play {
  background-color: #f8df12;
  border-radius: 25px;
  border: 3px solid #c72b35;
  color: #c72b35;
  font-weight: bold;
}

//          FORCE STYLES

.terms-and-condition-dialog {
  .mat-mdc-dialog-title,
  mat-dialog-title {
    font-weight: bold !important;
    text-align: center !important;
    font-size: 1.5rem !important;
  }
}

/* ==========================================================================
   Main styles
   ========================================================================== */
@font-face {
  font-family: 'libel_suitregular';
  src:
    url('/assets/fonts/libel-suit-rg-webfont.woff2') format('woff2'),
    url('/assets/fonts/libel-suit-rg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.font-libel {
  font-family: 'libel_suitregular';
}

.hard-red-bg {
  background-color: rgba(44, 6, 4, 0.81);
  //background-color: #262648;
}

.blue-2-border {
  border: 3px solid rgb(255 255 255 / 32%);
}

.gold-bg {
  background-color: #f5d208;
}

mat-button,
.mat-mdc-button:not(:disabled) {
  &.gold-button {
    background-color: #f5d208;
    font-family: 'libel_suitregular';
    text-transform: uppercase;
  }
}

.border-gold {
  border: none;
  background: linear-gradient(
    to right,
    #77530a,
    #ffd277,
    #77530a,
    #77530a,
    #ffd277,
    #77530a
  );
  background-size: 250%;
  background-position: left;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 1s;
  overflow: hidden;
  text-decoration: none;

  animation: backgroundAnimation 5s linear infinite;
}

.border-gold::before {
  position: absolute;
  content: '';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97%;
  height: 90%;
  transition-duration: 1s;
  background-color: #fff;
  background-size: 200%;
}

.border-gold-dialog {
  border: none;
  background: linear-gradient(
    to right,
    #77530a,
    #ffd277,
    #77530a,
    #77530a,
    #ffd277,
    #77530a
  );
  background-size: 250%;
  background-position: left;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 1s;
  overflow: hidden;
  text-decoration: none;
  animation: backgroundAnimation 5s linear infinite;

  //background-position: right;
  //transition-duration: 0.3s;
  //transform: scale(1.05);
}

@keyframes backgroundAnimation {
  0% {
    background-position: left;
  }
  50% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}

.no-padding {
  padding: 0 !important;
}

.mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
}

.cdk-overlay-dark-backdrop,
.red-bg {
  background-color: rgba(44, 6, 4, 0.81);
}

.mat-mdc-dialog-content {
  max-height: 86vh !important;
}

//.mat-toolbar-row,
//.mat-toolbar-single-row {
//  max-width: 600px;
//}
.mdc-text-field--filled.mdc-text-field--disabled .mdc-floating-label,
.mdc-text-field--filled.mdc-text-field--disabled
  .mdc-floating-label--float-above {
  color: #ebece5 !important;
}
